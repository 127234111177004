<template>
  <div>
    <a-drawer
      class="check-main"
      title="查看详情"
      placement="right"
      width="700"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <div class="check-info">
        <div class="info-tabs">
          <span
            class="tabs-btn"
            v-for="(item, index) in checkData"
            :key="index"
            :class="{
              'tabs-btn-first': item.sort === 0,
              'tabs-btn-tail': item.sort === checkData.length - 1,
              'tabs-btn-firsted': sortValue === 0 && item.sort === 0,
              'tabs-btn-tailed': sortValue === checkData.length - 1 && item.sort === checkData.length - 1,
              'tabs-active': sortValue !== 0 && sortValue !== checkData.length - 1 && sortValue === item.sort,
            }"
            @click="ontabs(item)"
            :title="item.name"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="tabData">
          <div class="link-name">
            <p>
              环节名称：<span>{{ tabData.name }}</span> 环节类型：<span>{{
                tabData.exerciseType === 'INTERACTIVE_EXERCISE' ? '互动练习' : '主观练习'
              }}</span>
              <a-button
                class="change-serious"
                @click="changeSerious"
                v-if="
                  tabData.exerciseType === 'SUBJECTIVE_EXERCISE' &&
                  tabData.exercises[0].type === 'GENERAL_SUBJECTIVE' &&
                  uuid === teacherId
                "
                :disabled="studentReview.publish"
                >修改</a-button
              >
            </p>
          </div>
          <div v-if="tabData.exerciseType === 'INTERACTIVE_EXERCISE'">
            <div class="question-list">
              <!-- 阅读题 -->
              <div class="question-item read-aloud">
                <!-- {{ homeWorkList[0].type }} -->
                <div v-for="i in homeWorkList" :key="i.uuid" style="margin-bottom: 20px">
                  <PictureBook v-if="i.type === 'PICTURE_BOOK'" v-bind="i" />
                  <Choice v-if="i.type === 'CHOICE'" v-bind="i" />
                  <BlankFilling v-if="i.type === 'BLANK_FILLING'" v-bind="i" />
                  <Connection v-if="i.type === 'CONNECTION'" v-bind="i" />
                  <div v-if="Array.isArray(i) && i[0].type === 'EXPLAN_CHOICE'" class="explan-choice">
                    <ExplanChoice v-bind="i[0]" :showExplan="showExplanId === i[0].id" />
                    <a-button size="large" class="explan-choice-button" @click="onShowExplan(i[0].id)"
                      >Problem analysis</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="tabData.exerciseType === 'SUBJECTIVE_EXERCISE'">
            <div class="question-item read-aloud">
              <div v-for="(i, index) in homeWorkList" :key="index" style="margin-bottom: 20px">
                <Subjective v-bind="i" v-if="i[0].type === 'SUBJECTIVE'" />
                <GeneralSubjective v-bind="i" />
              </div>
            </div>
          </div>
          <div v-else-if="tabData.exerciseType === 'SERIOUS_EXERCISE'">
            <div class="question-item read-aloud">
              <div v-for="(i, index) in homeWorkList" :key="index" style="margin-bottom: 20px">
                <Exercise :segments="i" segmentId="Segment1" />
              </div>
              <!-- <Exercise :segments="segments" segmentId="Segment1" /> -->
            </div>
          </div>
        </div>
        <div class="loading" v-if="showPopover">
          <a-spin size="large" />
        </div>
      </div>
    </a-drawer>
    <SeriousForm
      v-if="seriousVisible"
      :seriousVisible="seriousVisible"
      :seriousData="tabData"
      :scheduleData="scheduleData"
      :saveLoad="saveLoad"
      @closeBack="closeBack"
      @saveData="saveData"
    />
  </div>
</template>
<script>
import {
  Choice,
  PictureBook,
  BlankFilling,
  Connection,
  Subjective,
  ExplanChoice,
  Exercise,
  withIframe,
  GeneralSubjective,
} from '@wk/iexercise';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';
import storage from 'store';
import moment from 'moment';
import SeriousForm from './SeriousForm';
import { UUID } from '@/store/mutation-types';

export default {
  components: {
    SeriousForm,
    Choice: withIframe(Choice),
    PictureBook: withIframe(PictureBook),
    BlankFilling: withIframe(BlankFilling),
    Connection: withIframe(Connection),
    Subjective: withIframe(Subjective),
    ExplanChoice: withIframe(ExplanChoice),
    Exercise: withIframe(Exercise),
    GeneralSubjective: withIframe(GeneralSubjective),
  },
  props: {
     sortValueSel:{
      type:Number,
      default:0,
    },
    checkData: {
      type: Array,
      default: () => [],
    },
    studentReview: {
      type: Object,
      default: () => {},
    },
    checkDataFirst: {
      type: Object,
      default: () => {},
    },
    scheduleData: {
      type: Object,
      default: () => {},
    },
    homeworkTemplate: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uuid: storage.get(UUID),
      // loadingBol: false,
      visible: true,
      sortValue: 0,
      tabData: null,
      homeWorkList: [],
      showPopover: true,
      showExplanId: '',
      seriousVisible: false,
      saveLoad: false,
    };
  },
  watch: {
    checkData: {
      handler() {
        [this.tabData] = this.checkData;
        setTimeout(() => {
          if (this.tabData.exerciseType === 'SERIOUS_EXERCISE') {
            this.initSeriousCompont(this.tabData);
          } else {
            this.initCompont(this.tabData.exercises);
          }
        }, 200);
        this.showPopoverTime();
        this.ontabs(this.checkData[this.sortValue]);
      },
      deep: true,
    },
  },
  mounted() {
     this.sortValue=this.sortValueSel
    this.tabData= this.checkData[this.sortValue];
    setTimeout(() => {
      if (this.tabData.exerciseType === 'SERIOUS_EXERCISE') {
        this.initSeriousCompont(this.tabData);
      } else {
        this.initCompont(this.tabData.exercises);
      }
    }, 200);
    this.showPopoverTime();
  },
  methods: {
    moment,
    saveData(data) {
      this.checkData.forEach((item, index) => {
        if (item.uuid === data.saveData.uuid) {
          this.checkData[index] = data.saveData;
        }
      });
      this.homeworkTemplate.homeworkLink = this.checkData;
      const datas = {
        courseScheduleId: this.$route.query.uuid,
        courseSectionId: this.scheduleData.courseSectionId,
        homework: this.homeworkTemplate,
      };
      this.saveLoad = true;
      this.$post('/api/homework/bak', datas)
        .then((res) => {
          if (res.status === 200) {
            this.seriousVisible = false;
            if (data.type) {
              this.publish(datas);
            } else {
              this.saveLoad = false;
              this.$emit('initCourseHomeWork');
            }
          }
        })
        .finally(() => {
          this.saveLoad = false;
        });
    },
    publish(datas) {
      this.$post('/api/homework/bak/publish', datas).then(() => {
        this.$message.success('发布成功');
        this.saveLoad = false;
        this.$emit('initCourseHomeWork');
      });
    },
    // 修改主观题
    changeSerious() {
      this.seriousVisible = true;
    },
    onShowExplan(id) {
      this.showExplanId = this.showExplanId === id ? '' : id;
    },
    showPopoverTime() {
      setTimeout(() => {
        this.showPopover = false;
      }, 1000);
    },
    ontabs(values) {
      this.tabData = values;
      this.sortValue = this.tabData.sort;
      if (values.exerciseType === 'SERIOUS_EXERCISE') {
        this.initSeriousCompont(values);
      } else {
        this.initCompont(values.exercises);
      }
      this.showPopover = true;
      this.showPopoverTime();
    },
    initSeriousCompont(datas) {
      this.homeWorkList = [];
      switch (datas.exerciseType) {
        case 'SERIOUS_EXERCISE':
          this.serious(datas);
          break;
        default:
          break;
      }
    },
    initCompont(datas) {
      this.homeWorkList = [];
      datas.forEach((item) => {
        switch (item.type) {
          case 'PICTURE_BOOK':
            this.homeWorkList.push(this.pictureBookData(item));
            break;
          case 'CHOICE':
            this.homeWorkList.push(this.choiceData(item));
            break;
          case 'BLANK_FILLING':
            this.homeWorkList.push(this.blankFillingData(item));
            break;
          case 'CONNECTION':
            this.homeWorkList.push(this.connectionData(item));
            break;
          case 'SUBJECTIVE':
            this.homeWorkList.push([this.subjective(item)]);
            break;
          case 'EXPLAN_CHOICE':
            this.homeWorkList.push([this.explanOld(item)]);
            break;
          case 'GENERAL_SUBJECTIVE':
            this.homeWorkList.push([this.generalSubjective(item)]);
            break;
          default:
            break;
        }
      });
    },
    serious(datas) {
      datas.exercises.forEach((item) => {
        const node = [];
        const o = {
          title: '做一做',
          type: '复习巩固',
          id: 'Segment1',
          status: 'UN_FINISHED',
          cover: '/demo/segment-cover-1.png',
          paper: true,
        };
        switch (item.type) {
          case 'PICTURE_BOOK':
            node.push(this.pictureBookData(item));
            break;
          case 'CHOICE':
            node.push(this.choiceData(item));
            break;
          case 'BLANK_FILLING':
            node.push(this.blankFillingData(item));
            break;
          case 'CONNECTION':
            node.push(this.connectionData(item));
            break;
          case 'SUBJECTIVE':
            node.push(this.subjective(item));
            break;
          case 'EXPLAN_CHOICE':
            node.push(this.explanOld(item));
            break;
          case 'SINGLE_CHOICE':
            node.push(this.explan(item));
            break;
          default:
            break;
        }
        o.nodes = node;
        this.homeWorkList.push([o]);
      });
    },
    subjective(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        type: 'SUBJECTIVE',
        payload: {
          picture: data.contentOption.picture,
          status: 'finished',
          preview: true,
          task: data.contentOption.task,
          steps: data.contentOption.steps,
          homework: {},
        },
      };
    },
    connectionData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        type: 'CONNECTION',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    blankFillingData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        type: 'BLANK_FILLING',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    choiceData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        type: 'CHOICE',
        payload: {
          picture: data.contentOption.picture,
          options: data.contentOption.options,
          answer: data.contentOption.answer,
        },
      };
    },
    pictureBookData(data) {
      return {
        type: 'PICTURE_BOOK',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        pageSpan: 1,
        tag: data.tag,
        payload: [
          {
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
          },
        ],
      };
    },
    explanOld(data) {
      return {
        type: 'EXPLAN_CHOICE',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
        },
        tag: data.tag,
        payload: {
          ...data.contentOption,
          options: data.contentOption.options.map(item => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              }
            }
            return item;
          })
        },
      };
    },
    explan(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
        },
        tag: data.tag,
        payload: {
          ...data.contentOption,
          options: data.contentOption.options.map(item => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              }
            }
            return item;
          })
        },
      };
    },
    generalSubjective(data) {
      return {
        type: 'generalSubjective',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        payload: {
          status: 'finished',
          question: Object.assign(data.contentOption, { title: data.title }),
          answer: {},
        },
      };
    },
    closeBack() {
      this.seriousVisible = false;
    },
    onClose() {
      // this.loadingBol = false;
      this.$parent.cancal();
    },
  },
};
</script>
<style lang="less" scoped>
.check-info {
  .change-serious {
    width: 82px;
    height: 30px;
    border-radius: 8px;
    background-color: #009cff;
    color: #fff;
  }
  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
  .line {
    height: 1px;
    border: none;
    background-color: #009cff;
    margin-top: -15px;
  }
  .info-tabs {
    display: flex;
    justify-content: flex-start;
    .tabs-btn {
      display: inline-block;
      width: 100px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      text-align: center;
      color: #999;
      background-size: 100% 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: url(~@/assets/tab_part2_no.png) no-repeat center;
      background-size: 100% 100%;
      &:hover {
        cursor: pointer;
      }
    }
    .tabs-btn-first {
      background: url(~@/assets/tab_part1_no.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-firsted {
      color: #fff;
      background: url(~@/assets/tab_part1.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-tail {
      background: url(~@/assets/tab_part3_no.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-tailed {
      color: #fff;
      background: url(~@/assets/tab_part3.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-active {
      color: #fff;
      background: url(~@/assets/tab_part2.png);
      background-size: 100% 100%;
    }
  }
  .link-name {
    background: #fafafa;
    border-radius: 10px;
    padding: 0 20px;
    margin: 30px 0;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 54px;
      color: #666;
      span {
        color: #333;
        font-weight: 600;
        margin-right: 40px;
      }
    }
  }
  .question-type {
    color: #333;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      padding: 2px 10px;
      border-radius: 6px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      margin-left: 10px;
    }
  }
  .question-list {
    overflow-y: auto;
    .question-item {
      border-top: 1px solid #eee;
      padding: 20px 0;
    }
    p {
      span {
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        padding: 2px 10px;
        border-radius: 6px;
        border: 1px solid rgba(151, 151, 151, 0.3);
        margin-left: 10px;
      }
    }
  }
  .read-aloud {
    p {
      color: #333;
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 10px;
    }
    img {
      width: 320px;
      height: 160px;
    }
  }
  .loading {
    width: 100%;
    height: calc(100vh - 120px);
    background-color: rgba(255, 255, 255, 1);
    padding: 50% 0 0 0;
    text-align: center;
    line-height: 100%;
    font-size: 60px;
    position: absolute;
    bottom: 0;
    right: 20px;
    z-index: 9999;
  }
  .explan-choice {
    position: relative;
    .explan-choice-button {
      border-radius: 30px;
      position: absolute;
      bottom: 0px;
      right: 0;
      z-index: 100000;
    }
  }
}
</style>
