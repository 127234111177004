<template>
  <a-drawer
    class="serious-main"
    title="修改题目"
    placement="right"
    width="700"
    :closable="true"
    :visible="seriousVisible"
    @close="onClose"
  >
    <div class="serious-info">
      <div class="serios-title">
        <p><span>科目：</span> {{ seriousData.exercises[num].subject === 'CHINESE' ? '中文' : '数学' }}</p>
        <p><span>内部名称：</span> {{ seriousData.exercises[num].internalTitle }}</p>
        <p v-if="!changeBol">
          <span>题干：</span> {{ newTitle || seriousData.exercises[num].title
          }}<a-icon type="edit" @click="onChangeTitle" />
        </p>
        <div v-else><span>题干：</span><a-textarea class="intTitle" @blur="onGetTitle" v-model="newTitle" /></div>
      </div>
      <div class="universal">
        <span>提示语音：</span>
        <div class="universal-main" v-if="seriousData.exercises[num].contentOption.audios">
          <div class="universal-file" v-for="item in audioUrls" :key="item">
            <audio :src="item" controls preload></audio>
            <a-icon class="del" type="delete" @click="delFile('audioUrls', item)" />
          </div>
          <div class="uploadCls">
            <p>支持MP3格式，文件不超过5分钟，大小不超过5M</p>
            <div>
              <a-upload
                name="audios"
                list-type="picture"
                :show-upload-list="false"
                @change="previewAudio"
                :before-upload="beforeUploadAudio"
              >
                <div>
                  <div class="ant-upload-text"><a-icon type="upload" />上传音频</div>
                </div>
              </a-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="universal">
        <span>题目配图：</span>
        <div class="universal-main">
          <div class="img-main">
            <div
              class="universal-img"
              @mouseover="selectImg(item)"
              @mouseout="selectEle = ''"
              v-for="item in imageUrls"
              :key="item"
            >
              <img :src="item" alt="" />
              <img
                v-show="selectEle === item"
                @click="delFile('imageUrls', item)"
                class="close"
                src="@/assets/close.png"
                alt=""
              />
            </div>
          </div>
          <div class="uploadCls">
            <p>图片最多5张，每张大小不超过3M</p>
            <div>
              <a-upload
                name="imgaes"
                list-type="picture"
                :show-upload-list="false"
                @change="previewImage"
                :before-upload="beforeUploadImage"
              >
                <div>
                  <div class="ant-upload-text"><a-icon type="upload" />上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </div>
      </div>

      <div class="universal">
        <span>题目视频：</span>
        <div class="universal-main">
          <div class="universal-file" v-for="item in videoUrls" :key="item">
            <video :src="item" controls="controls"></video>
            <a-icon class="del" type="delete" @click="delFile('videoUrls', item)" />
          </div>
          <div class="uploadCls">
            <p>视频最多2个，每个大小不超过50M</p>
            <a-upload
              action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
              list-type="picture"
              :showUploadList="true"
              :preview-file="previewVideo"
              :before-upload="beforeUploadVideo"
            >
              <div>
                <div class="ant-upload-text"><a-icon type="upload" />上传视频</div>
              </div>
            </a-upload>
          </div>
        </div>
      </div>
      <div class="universal">
        <span>题目文件：</span>
        <div class="universal-main">
          <div class="universal-file" v-for="item in fileUrls" :key="item" :src="item">
            <p @click="downloadFile(item)"><a-icon type="link" /> {{ item.substring(item.lastIndexOf('/') + 1) }}</p>
            <a-icon class="del" type="delete" @click="delFile('fileUrls', item)" />
          </div>
          <div class="uploadCls">
            <p>文件最多2个，支持PDF，每个大小不超过50M</p>
            <a-upload
              action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
              list-type="picture"
              :showUploadList="true"
              :preview-file="previewFile"
              :before-upload="beforeUploadFile"
            >
              <div>
                <div class="ant-upload-text"><a-icon type="upload" />上传文件</div>
              </div>
            </a-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="seriousTools">
      <a-button @click="onClose">取消</a-button>
      <a-button type="primary" @click="onBack(0)" :disabled="saveLoad">保存</a-button>
      <a-button
        type="primary"
        @click="onPublish"
        :disabled="moment().format('X') < moment(scheduleData.endDateTime).format('X')"
        >保存并发布</a-button
      >
    </div>
  </a-drawer>
</template>
<script>
import moment from 'moment';
// import Voice from '@/components/WKrecorder/voice.vue';
import { imgpostUrl } from '@/api/register';

export default {
  // components: { Voice },
  props: {
    seriousVisible: {
      type: Boolean,
      default: false,
    },
    saveLoad: {
      type: Boolean,
      default: false,
    },
    seriousData: {
      type: Object,
      default: () => {},
    },
    scheduleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isBol: true,
      num: 0,
      is_showVoice: false,
      fileList: [],
      isUp: false,
      audioUrls: JSON.parse(JSON.stringify(this.seriousData.exercises[0].contentOption.audios)),
      imageUrls: JSON.parse(JSON.stringify(this.seriousData.exercises[0].contentOption.pictures)),
      videoUrls: JSON.parse(JSON.stringify(this.seriousData.exercises[0].contentOption.videos)),
      fileUrls: JSON.parse(JSON.stringify(this.seriousData.exercises[0].contentOption.files)),
      fileName: '',
      isUpLoad: false,
      selectEle: '',
      changeBol: false,
      newTitle: '',
    };
  },
  methods: {
    moment,
    onGetTitle() {
      this.changeBol = false;
    },
    onChangeTitle() {
      this.newTitle = this.seriousData.exercises[this.num].title;
      this.changeBol = true;
    },
    selectImg(item) {
      this.selectEle = item;
    },
    delFile(type, data) {
      this[type] = this[type].filter((item) => item !== data);
    },
    onPublish() {
      this.onBack('publish');
    },
    downloadFile(fileUrl) {
      const alink = document.createElement('a');
      alink.download = this.fileName;
      alink.href = fileUrl;
      alink.click();
      // document.body.removeChild(alink);
    },
    onBack(type) {
      const saveData = JSON.parse(JSON.stringify(this.seriousData));
      if (this.audioUrls || this.imageUrls || this.videoUrls || this.fileUrls) {
        saveData.exercises[this.num].contentOption.audios = this.audioUrls;
        saveData.exercises[this.num].contentOption.pictures = this.imageUrls;
        saveData.exercises[this.num].contentOption.videos = this.videoUrls;
        saveData.exercises[this.num].contentOption.files = this.fileUrls;
        saveData.exercises[this.num].title = this.newTitle;
      }
      this.$emit('saveData', { saveData, type });
    },
    beforeUploadFile(file) {
      const isapplication = file.type.includes('application/pdf');
      this.isUpLoad = isapplication;
      if (!isapplication) {
        this.$message.error('目前支持PDF格式文件');
      }
      return isapplication;
    },
    beforeUploadAudio(file) {
      const isapplication = file.type.includes('audio/mpeg');
      this.isUpLoad = isapplication;
      if (!isapplication) {
        this.$message.error('请上传音频文件文件');
      }
      return isapplication;
    },
    beforeUploadImage(file) {
      const isapplication = file.type.includes('image');
      this.isUpLoad = isapplication;
      if (!isapplication) {
        this.$message.error('请上传正确图片格式');
      }
      return isapplication;
    },
    beforeUploadVideo(file) {
      const isapplication = file.type.includes('video');
      this.isUpLoad = isapplication;
      if (!isapplication) {
        this.$message.error('请上传正确视频格式');
      }
      return isapplication;
    },
    previewImage(info) {
      if (this.isUp) return;
      if (this.imageUrls.length >= 5) {
        this.$message.error('图片数量最多五张');
        return;
      }
      this.isUp = true;
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad) {
          this.uploadToOssOne(info, res.data.content, 'img');
        } else {
          this.isUp = false;
        }
      });
    },
    previewAudio(file) {
      if (this.isUp) return;
      if (this.audioUrls.length >= 2) {
        this.$message.error('音频最多为两个');
        return;
      }
      this.isUp = true;
      const o = {
        file: {
          originFileObj: file.file.originFileObj,
        },
      };
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad) {
          this.uploadToOssOne(o, res.data.content, 'audio');
        } else {
          this.isUp = false;
        }
      });
    },
    previewVideo(file) {
      if (this.videoUrls.length >= 2) {
        this.$message.error('视频最多为两个');
        return;
      }
      const o = {
        file: {
          originFileObj: file,
        },
      };
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad) {
          this.uploadToOssOne(o, res.data.content, 'video');
        } else {
          this.isUp = false;
        }
      });
    },
    previewFile(file) {
      if (this.fileUrls.length > 1) {
        this.$message.error('文件最多为两个');
        return;
      }
      const o = {
        file: {
          originFileObj: file,
        },
      };
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad) {
          this.fileName = '';
          this.fileName = file.name;
          this.uploadToOssOne(o, res.data.content, 'file');
        } else {
          this.isUp = false;
        }
      });
    },
    uploadToOssOne(info, fileData, type) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData).then((res) => {
        this.isUp = false;
        if (type === 'file') {
          this.fileUrls.push(res.data.url);
        } else if (type === 'video') {
          this.videoUrls.push(res.data.url);
        } else if (type === 'img') {
          this.imageUrls.push(res.data.url);
        } else if (type === 'audio') {
          this.audioUrls.push(res.data.url);
        }
      });
    },
    onClose() {
      this.$emit('closeBack');
    },
    cancal() {
      this.is_showVoice = false;
    },
  },
};
</script>
<style lang="less">
.serious-main {
  .intTitle {
    width: 85%;
    margin: 5px 0;
  }
  .serios-title {
    font-size: 16px;
    color: #333;
    span {
      display: inline-block;
      width: 80px;
      height: 22px;
      font-size: 16px;
      color: #333;
      text-align: right;
    }
  }
  .universal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    p {
      margin: 10px 0 10px;
    }
    & > span {
      display: inline-block;
      width: 80px;
      height: 22px;
      font-size: 16px;
      color: #333;
      text-align: right;
    }
    .universal-main {
      width: 86%;
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #eee;
      img,
      video {
        width: 160px;
        height: 90px;
        margin: 5px;
      }
      .universal-img {
        position: relative;
        width: 175px;
        height: 105px;
        .close {
          width: 14px;
          height: 14px;
          position: absolute;
          top: -4px;
          right: 0px;
        }
      }
      .uploadCls {
        display: flex;
        justify-content: space-between;
      }
    }
    .ant-upload-text {
      border: 1px solid #dedede;
      border-radius: 10px;
      padding: 5px 20px;
    }
    .universal-file {
      display: flex;
      align-items: center;
      p {
        margin: 5px 0;
      }
    }
    .del {
      color: #ff5353;
    }
  }
  .img-main {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .universal:last-child {
    padding: 0 0 50px;
  }
  .upload {
    width: 100%;
  }
  img,
  video {
    width: 300px;
    height: 200px;
    // margin: 0 20px;
  }
  .ant-upload-list-picture {
    display: none;
  }
}
.seriousTools {
  width: 666px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eee;
  background-color: #fff;
  .ant-btn {
    margin: 0 10px;
  }
}
.ant-btn-primary:hover {
  background-color: #009cff;
}
.ant-btn-primary {
  background-color: #009cff;
}
</style>
