<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="学生">
              <a-input
                placeholder="输入学生姓名或学号"
                v-model="searchName"
                allow-clear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="科目">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="subject">
                <a-select-option :value="item.value" v-for="(item, index) in subjectArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课节名称">
              <a-input
                placeholder="请输入"
                v-model="searchSectionName"
                allow-clear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="作业状态">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="homeWorkStatus">
                <a-select-option :value="item.value" v-for="(item, index) in homeWorkStatusArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课程类型">
              <a-select placeholder="请选择" allowClear @change="initSearchData('search')" v-model="courseType">
                <a-select-option :value="item.value" v-for="(item, index) in courseTypeArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="上课时间">
              <a-range-picker
                style="width: 100%"
                @change="onChangeDate"
                v-model="classDateArr"
                allowClear
                :show-time="{
                  format: 'HH:mm',
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                }"
                format="YYYY-MM-DD HH:mm"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-wrap">
      <div class="set-wrap">
        <a-button
          type="primary"
          style="margin-right: 10px"
          ghost
          @click="createDelivery"
          :loading="loadingBtn"
          v-if="userInfo.roleArr.includes('CLASS_ADMIN')"
          >新建群发</a-button
        >
      </div>
      <a-table
        :loading="showLoading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="studentName" slot-scope="studentName, record">
          <span>{{ record.studentName }} ({{ record.studentCode }})</span>
        </template>
        <template slot="sectionName" slot-scope="sectionName, record">
          <a-tooltip placement="topLeft" :key="record.uuid">
            <template slot="title">
              <span>{{ sectionName }}</span>
              <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
            </template>
            <span>{{ sectionName }}</span>
            <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
          </a-tooltip>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="status" slot-scope="status">
          <span>{{ status ? initOptionsType(status, homeWorkStatusArr) : '-' }}</span>
        </template>
        <template slot="correctRate" slot-scope="correctRate">
          <span>{{ correctRate ? correctRate : '-' }}</span>
        </template>
        <template slot="startDateTime" slot-scope="startDateTime, record">
          <span>{{
            `${$moment(startDateTime).format('YYYY-MM-DD HH:mm')} - ${$moment(record.endDateTime)
              .format('YYYY-MM-DD HH:mm')
              .substring(11, 16)}`
          }}</span>
        </template>
        <template slot="submitTime" slot-scope="submitTime">
          <span>{{ submitTime ? $moment(submitTime).format('YYYY-MM-DD HH:mm') : '-' }}</span>
        </template>

        <template slot="operation" slot-scope="operation, record" v-if="record.status !== 'NO_PUBLISH'">
          <span class="operation" @click="lookDetail(record)">查看</span>
        </template>
      </a-table>
    </div>

    <component
      :sortValueSel="sortValueSel"
      :is="currentView"
      :homeworkTemplate="homeworkTemplate"
      :studentReview="studentReview"
      :studentId="studentId"
      :checkData="checkData"
      :teacherId="teacherId"
      :subject="homeWorkSubject"
      :courseScheduleId="courseScheduleId"
      @openCheckOperation="openCheckOperation"
    ></component>
    <!-- 中文课后作业未提交 -->
    <a-drawer title="查看详情" placement="right" width="700" :closable="true" :visible="checkVisible" @close="cancal">
      <CheckHomeWork
        :subject="homeWorkSubject"
        :checkData="checkData"
        :studentReview="studentReview"
        :homeworkTemplate="homeworkTemplate"
      />
    </a-drawer>
    <newMassDelivery
      v-if="showDelivery"
      @closeDelivery="closeDelivery"
      fromOrigin="homework"
      :noBindCodes="noBindCodes"
      :totalPeople="totalPeople"
      :receiverCount="receiverCount"
      :receiverIds="receiverIds"
      :senderId="senderId"
    ></newMassDelivery>
  </div>
</template>

<script>
import moment from 'moment';
import storage from 'store';
import { CheckHomeWork } from '@wk/tch-sc';
import defaultImg from '@/assets/service_pic_head.png';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import CheckOperation from '@/views/operation/CheckOperation';
import ReviewOperation from '@/views/operation/ReviewOperation';
import { queryHomeWorkList, queryCourseSchedule, courseHomeWork, queryHomeWorkSendNum } from '@/api/headTeacher';
import newMassDelivery from '@/views/student/newMassDelivery';
import { getDdlLabel } from '@/utils/util';

export default {
  name: 'courseDurationApprovalNew',
  components: { InfoTitle, CheckHomeWork, ReviewOperation, CheckOperation, newMassDelivery },
  data() {
    return {
      getDdlLabel,
      queryParam: {},
      receiverIds: [],
      senderId: '',
      totalPeople: 0,
      receiverCount: 0,
      noBindCodes: [],
      showDelivery: false,
      loadingBtn: false,
      searchSectionName: undefined,
      studentId: '',
      showLoading: false,
      currentView: null, // 组件名称
      courseScheduleId: '',
      teacherId: '',
      checkVisible: false,
      submited: [],
      noSubmited: [],
      homeworkTemplate: null,
      homeWorkSubject: '',
      studentReview: [],
      checkData: {},

      sortValueSel: 0, // 默认选中预览题目tab
      subject: undefined,
      homeWorkStatus: undefined,
      courseType: undefined,
      searchName: '',
      endTime: null,
      startTime: null,
      classDateArr: [],
      homeWorkStatusArr: [
        { label: '未发布', value: 'NO_PUBLISH' },
        { label: '未完成', value: 'NO_COMPLETE' },
        { label: '已完成', value: 'COMPLETED' },
        { label: '部分完成', value: 'PARTIALLY_COMPLETED' },
      ],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      courseTypeArr: [
        { label: '标准课', value: 'STANDARD' },
        { label: '体验课', value: 'TRIAL' },
        { label: '公开课', value: 'PUBLIC' },
      ],
      userInfo: {},
      courseScheduleObj: {},
      tableSorter: {},
      remarkTC: '',
      examineTitle: '',
      visibleResult: false,
      actualData: {},
      visibleExamine: false,
      defaultImg,
      formInfoData: {},
      selType: 'PENDING',
      headmasterList: [],
      submitterId: undefined,
      transactionCategory: undefined,
      transactionCategoryArr: [
        { label: '课时转移', value: 'TRANSFER' },
        { label: '课时返还', value: 'RECOVER' },
        { label: '课时赠送', value: 'COMPENSATE' },
      ],
      approveStatus: undefined,
      approveStatusArr: [
        { label: '待审核', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
      ],
      visible: false,
      pageNum: 1,

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',

          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '学生姓名',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/studentDetail',
                    query: {
                      uuid: record.studentId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '课节名称',
          dataIndex: 'sectionName',
          key: 'sectionName',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'sectionName' },
        },
        {
          title: '课节时间',
          dataIndex: 'startDateTime',
          key: 'startDateTime',
          sorter: true,
          scopedSlots: { customRender: 'startDateTime' },
        },
        {
          title: '作业状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime',
          key: 'submitTime',

          scopedSlots: { customRender: 'submitTime' },
        },
        {
          title: '正确率',
          dataIndex: 'correctRate',
          key: 'correctRate',
          scopedSlots: { customRender: 'correctRate' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      current: 0,
      pageSize: 10,

      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },

      loading: false,
    };
  },
  methods: {
    moment,
    querySendNum() {
      queryHomeWorkSendNum(this.queryParam)
        .then((res) => {
          this.loadingBtn = false;
          this.showLoading = false;
          this.showDelivery = true;
          const data = res.data.content;
          this.totalPeople = data.total;
          this.receiverCount = data.receiverCount;
          this.receiverIds = data.receiverIds;
          this.senderId = data.senderId;
          this.noBindCodes = data.noBindCodes;
        })
        .catch((res) => {
          this.loadingBtn = false;
        });
    },
    closeDelivery() {
      this.showDelivery = false;
    },
    createDelivery() {
      this.loadingBtn = true;
      this.querySendNum();
    },
    cancal() {
      this.currentView = null;
      this.checkVisible = false;
    },
    openCheckOperation(sort) {
      // 从题目详情点击进去预览题目，选中对应的tab
      this.sortValueSel = sort;

      // 打开预览作业
      this.currentView = 'CheckOperation';
      this.homeworkTemplate = session.get('homeworkTemplate');
    },
    onChangeDate(date) {
      if (date.length > 0) {
        this.startTime = date[0].format('YYYY-MM-DD HH:mm');
        this.endTime = date[1].format('YYYY-MM-DD HH:mm');
      } else {
        this.startTime = null;
        this.endTime = null;
      }
    },
    playback() {
      window.open(this.courseScheduleObj.liveUrl, '_blank');
    },

    // 查询课节
    queryCourseSchedule(uuid) {
      const params = {
        uuid,
      };

      queryCourseSchedule(params).then((res) => {
        this.courseScheduleObj = res.data.content;
      });
    },

    hrefStudent() {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: this.formInfoData.studentId },
      });
      window.open(routeUrl.href, '_blank');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },

    onClose() {
      this.visible = false;
    },
    lookDetail(record) {
      this.homeWorkSubject = record.subject;
      this.courseScheduleId = record.courseScheduleId;
      this.teacherId = record.teacherId;
      this.studentId = record.studentId;
      this.initCourseHomeWork(record);
    },
    // 获取预览作业
    initCourseHomeWork(record) {
      const params = {
        courseScheduleId: record.courseScheduleId,
        courseSectionId: record.sectionId,
        studentId: record.studentId,
        teacherId: record.teacherId,
      };
      courseHomeWork(params).then((res) => {
        this.studentReview = res.data.content;
        // 判断课后作业有没有发布，发布了，判断学生是否提交了作业，提交了展示提交的作业，否则展示预览作业
        if (this.studentReview.publish) {
          // 预览老师发布的作业
          this.checkData = res.data.content?.homeworkTemplate?.homeworkLink;
          this.homeworkTemplate = res.data.content?.homeworkTemplate;

          this.submited = this.studentReview.stuSchedules.filter((item) => {
            return item.student.submitStatus;
          });
          this.noSubmited = this.studentReview.stuSchedules.filter((item) => {
            return !item.student.submitStatus;
          });
          if (this.studentReview?.student?.caSubmitStatus) {
            // 预览学生提交的作业,里面也要展示未提交的
            this.currentView = 'ReviewOperation';
          } else {
            this.sortValueSel = 0;
            this.checkVisible = true;
          }
        } else {
          this.$message.warning('暂无作业发布');
        }
      });
    },

    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;

      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;

      this.initSearchData();
    },

    resetCondition() {
      this.searchSectionName = undefined;
      this.searchName = undefined;
      this.homeWorkStatus = undefined;
      this.courseType = undefined;
      this.classDateArr = [];
      this.startTime = null;
      this.endTime = null;
      this.subject = undefined;
      this.pageNum = 1;
      this.pageSize = 10;
      this.initSearchData('search');
    },
    initSearchData(act) {
      this.showLoading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        name: this.searchName,
        ownerId: this.userInfo.userID,
        subject: this.subject,
        sectionName: this.searchSectionName,
        status: this.homeWorkStatus,
        courseType: this.courseType,
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        if (this.tableSorter.columnKey === 'startDateTime') {
          params.orderBy = `courseSchedule.${this.tableSorter.columnKey} ${sort}`;
        } else {
          params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
        }
      }
      this.queryParam = { ...params };
      queryHomeWorkList(params).then((res) => {
        const data = res.data.content;
        this.tableData = data.content;
        this.current = data.currentPage;
        this.pageSize = data.pageSize;
        this.initPagination.total = data.totalCount;
        this.initPagination.current = data.currentPage;
        this.showLoading = false;
      });
    },
  },
  created() {
    this.userInfo = storage.get('userInfo');
    this.subject = this.userInfo.subject;
    if (this.userInfo.roleName === 'CLASS_ADMIN') {
      this.selType = 'OWN';
    }
  },
  mounted() {
    const { endTime, startTime, status, subject } = this.$route.query || {};

    this.startTime = startTime || this.$moment(endTime).subtract(7, 'days').format('YYYY-MM-DD HH:mm');
    this.endTime = endTime || this.$moment().format('YYYY-MM-DD HH:mm');
    this.homeWorkStatus = status;
    this.subject = subject;

    this.classDateArr = [startTime, endTime];
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.operation {
  color: #3372fe;
  cursor: pointer;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}

.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.task-info {
  height: calc(100vh - 130px);
  .task-type {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      padding: 2px 10px;
      background-color: #f7f7f7;
      border-radius: 6px;
      margin-right: 10px;
    }
  }
  .task-form {
    border-bottom: 1px solid #eee;
    .form-name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 2px 10px;
        border-radius: 6px;
        margin: 0 10px;
      }
    }
    .pending-status {
      color: #ffbf00;
      background-color: rgba(255, 191, 0, 0.09);
    }
    .success-status {
      color: #00cf64;
      background-color: rgba(0, 207, 100, 0.09);
    }
    .refuse-status {
      color: #ff5353;
      background-color: rgba(255, 83, 83, 0.09);
    }
    .form-teacher {
      font-size: 16px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin-bottom: 20px;
      .dueDate {
        color: #ff5454;
      }
      span {
        color: #333;
      }
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .form-des {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
    .form-imgs {
      img {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;
      }
      margin-bottom: 20px;
    }
  }
  .courseInfo {
    padding: 20px 0;
    .course-info {
      padding: 20px 16px;
      background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
      border-radius: 20px;
      .course-name {
        font-size: 16px;
        font-weight: 600;
        color: #009cff;
        line-height: 22px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
      .course-type {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .taskFormBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 700px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    padding: 10px 16px;
    background: '#fff';
    text-align: right;
    z-index: 1;
    img {
      margin: 0 10px 0 0;
    }
    .ant-btn-primary {
      // background-color: #009cff;
    }
  }
}
.apply-item {
  margin-bottom: 10px;
  display: flex;
}
.apply-label {
  color: rgba(31, 45, 61, 0.4);
  min-width: 43px;
}
.apply-content {
  color: #1f2d3d;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;

  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.line-wrap {
  margin-top: 20px;

  border-bottom: 1px solid #eee;
}
.apply-detail {
  margin-top: 20px;
}
.examine-item {
  margin-bottom: 14px;
  display: flex;
}
.examine-label {
  width: 80px;
}
.examine-content {
  flex: 1;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);
  height: 280px;
  display: flex;
}
.trans-itemWrap {
  flex: 1;
  padding: 10px 20px;
}
.trans-item {
  margin-top: 5px;
}
.trans-itemLabel {
}
.set-wrap {
  padding-bottom: 16px;
  text-align: right;
}
</style>
